<template>
    <section
        v-loading.fullscreen.lock="m__loading"
        class="mx-auto w-full max-w-[1500px]"
    >
        <div class="mx-auto w-[520px]">
            <div v-if="active_collapse === 1" class="component-box mb-4">
                <p class="mb-8 text-lg font-semibold text-center">
                    Tạo nhanh tài khoản quảng cáo
                </p>

                <el-alert
                    title="Nếu quý khách không muốn kết nối tài khoản Tiktok doanh nghiệp, quý khách có thể tạo nhanh tài khoản quảng cáo và sử dụng tài khoản này để tạo quảng cáo trên Atame, giúp tiết kiệm thời gian chuẩn bị tài nguyên"
                    type="success"
                    class="mb-2"
                    :closable="false"
                >
                </el-alert>

                <el-button
                    type="danger"
                    plain
                    class="border-border w-full rounded-2xl border-[1px] flex items-center !h-[60px] hover:border-brand-atosa--light"
                    @click=";(active_collapse = 2), (is_create_fast = true)"
                    >Tạo nhanh</el-button
                >
            </div>
            <div class="component-box">
                <div class="relative">
                    <el-button
                        v-if="active_collapse !== 1 && active_collapse !== 3"
                        class="top-1/2 absolute left-0 -translate-y-1/2"
                        size="mini"
                        icon="el-icon-back"
                        @click="active_collapse--"
                        >{{ $t('button.return') }}
                    </el-button>

                    <p class="mb-8 text-lg font-semibold text-center">
                        <span v-if="active_collapse === 1">
                            Liên kết tài khoản Tiktok Business
                        </span>
                        <span v-else-if="active_collapse === 2">
                            {{
                                $t(
                                    'page.connect_tiktok_account.title_ads_account'
                                )
                            }}
                        </span>
                        <span v-else-if="active_collapse === 3">
                            {{
                                $t(
                                    'page.connect_tiktok_account.title_business_center'
                                )
                            }}
                            (BC)
                        </span>
                    </p>
                </div>

                <div v-if="active_collapse === 1">
                    <el-alert
                        :title="$t('common.desc_tiktok_business')"
                        type="success"
                        class="mb-2"
                        :closable="false"
                    >
                    </el-alert>

                    <div
                        v-for="item in tiktok_businesses"
                        :key="item.id"
                        class="border-border group hover:border-brand-atosa--light rounded-2xl border-[1px] p-3 flex items-center mb-4 cursor-pointer"
                        @click="handleSelectTiktokAccount(item)"
                    >
                        <el-avatar :size="52">
                            <img
                                v-if="item.avatar_url"
                                :src="item.avatar_url"
                                alt=""
                            />
                            <p v-else>{{ item.email }}</p>
                        </el-avatar>

                        <div class="ml-4">
                            <p
                                class="group-hover:font-semibold group-hover:text-brand-atosa--light text-sm"
                            >
                                {{ item.user_name }}
                            </p>
                            <span class="component-text-desc">
                                #{{ item.core_user_id }}
                            </span>
                        </div>
                    </div>

                    <div
                        class="border-border rounded-2xl border-[1px] p-3 flex items-center"
                    >
                        <div
                            class="bg-bg util-flex-center w-14 h-14 rounded-full"
                        >
                            <i class="el-icon-user text-2xl"></i>
                        </div>

                        <p class="ml-4 text-sm font-medium">
                            {{
                                $t(
                                    'page.connect_tiktok_account.connect_for_business'
                                )
                            }}
                        </p>

                        <el-button
                            class="ml-auto !rounded-3xl !h-10"
                            icon="el-icon-connection"
                            size="medium"
                            type="primary"
                            :disabled="has_connecting"
                            @click="handleConnectTiktokAccount"
                            >{{ $t('button.connect') }}
                        </el-button>
                    </div>
                </div>

                <div v-else-if="active_collapse === 2">
                    <el-form
                        ref="form_create_ads_account"
                        label-position="top"
                        :model="form_create_ads_account.values"
                        :rules="form_create_ads_account.rules"
                    >
                        <el-form-item
                            v-if="!is_create_fast"
                            :label="$t('common.business_center')"
                            prop="target_bc_id"
                        >
                            <el-select
                                v-model="
                                    form_create_ads_account.values.target_bc_id
                                "
                                filterable
                                class="w-full"
                                size="medium"
                                :placeholder="$t('common.business_center')"
                            >
                                <el-option-group
                                    :label="$t('common.business_center')"
                                >
                                    <el-option
                                        v-for="item in business_centers"
                                        :key="item.bc_id"
                                        :label="item.name"
                                        :value="item.bc_id"
                                    >
                                        <div
                                            class="flex items-center py-1 space-x-2"
                                        >
                                            <div class="flex flex-col">
                                                <span
                                                    class="leading-5 text-xs font-semibold"
                                                >
                                                    {{ item.name }}
                                                </span>
                                                <span
                                                    class="component-text-desc"
                                                >
                                                    #{{ item.bc_id }}
                                                </span>
                                            </div>
                                        </div>
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </el-form-item>

                        <el-form-item
                            class="w-full mt-6"
                            :label="$t('common.ad_account_name')"
                            prop="advertiser_info.name"
                        >
                            <el-input
                                v-model="
                                    form_create_ads_account.values
                                        .advertiser_info.name
                                "
                                class="w-full"
                                size="medium"
                                :placeholder="
                                    $t('input.placeholder.please_input')
                                "
                            >
                            </el-input>
                        </el-form-item>

                        <div class="flex mt-6 space-x-4">
                            <el-form-item
                                :label="$t('common.timezone')"
                                class="w-2/3"
                                prop="advertiser_info.timezone"
                            >
                                <el-select
                                    v-model="
                                        form_create_ads_account.values
                                            .advertiser_info.timezone
                                    "
                                    filterable
                                    class="w-full"
                                    size="medium"
                                    :placeholder="$t('common.timezone')"
                                >
                                    <el-option
                                        v-for="item in p__tiktok_timezones"
                                        :key="item.code"
                                        :label="`${item.code} - ${item.time}`"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item
                                class="w-1/3"
                                :label="$t('common.currency')"
                                prop="advertiser_info.currency"
                            >
                                <el-select
                                    v-model="
                                        form_create_ads_account.values
                                            .advertiser_info.currency
                                    "
                                    filterable
                                    class="w-full"
                                    size="medium"
                                    :disabled="true"
                                    :placeholder="
                                        $t('input.placeholder.please_select')
                                    "
                                >
                                    <el-option
                                        v-for="item in p__tiktok_currency"
                                        :key="item.code"
                                        :label="`${item.code} - ${item.currency}`"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="text-center">
                            <el-button
                                class="block mx-auto mt-8"
                                icon="el-icon-plus"
                                type="primary"
                                size="medium"
                                @click="submitFormCreateAdsAccount()"
                                >{{ $t('button.create') }}
                            </el-button>
                        </div>
                    </el-form>
                </div>

                <div v-else-if="active_collapse === 3">
                    <div v-show="!is_creating_bc">
                        <div class="flex flex-col space-y-4">
                            <div
                                v-for="item in business_centers"
                                :key="item.bc_id"
                                class="cursor-pointer group border-border hover:border-brand-atosa--light rounded-2xl border-[1px] p-3 flex items-center"
                                :class="{
                                    'border-brand-atosa--light':
                                        form_assign_bc.target_bc_id ===
                                        item.bc_id
                                }"
                                @click="handleSelectedBC(item.bc_id)"
                            >
                                <div
                                    class="bg-bg util-flex-center w-14 h-14 rounded-full"
                                >
                                    <i class="el-icon-suitcase text-2xl"></i>
                                </div>
                                <div class="ml-4">
                                    <p
                                        class="group-hover:font-semibold group-hover:text-brand-atosa--light text-sm"
                                        :class="{
                                            'font-semibold text-brand-atosa--light':
                                                form_assign_bc.target_bc_id ===
                                                item.bc_id
                                        }"
                                    >
                                        {{ item.name }}
                                    </p>
                                    <p class="component-text-desc">
                                        #{{ item.bc_id }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="ml- mt-4 text-sm">
                            {{
                                $t('common.you_can_create_up_to_bc', {
                                    number: max_bc_number
                                })
                            }}.
                        </div>
                        <div class="flex justify-center space-x-3">
                            <el-button
                                class="mt-8"
                                icon="el-icon-plus"
                                size="medium"
                                :disabled="
                                    business_centers.length >= max_bc_number
                                "
                                @click="is_creating_bc = true"
                                >{{ $t('button.create') }}
                            </el-button>
                            <el-button
                                class="mt-8"
                                icon="el-icon-share"
                                size="medium"
                                :disabled="!form_assign_bc.target_bc_id"
                                @click="assignAdAccountToTargetBC"
                                >{{
                                    $t(
                                        'page.connect_tiktok_account.assign_to_bc'
                                    )
                                }}
                            </el-button>
                        </div>
                    </div>

                    <div v-show="is_creating_bc">
                        <el-button
                            v-show="business_centers.length"
                            type="text"
                            icon="el-icon-back"
                            size="medium"
                            class="mb-4"
                            @click="is_creating_bc = false"
                            >{{ $t('page.connect_tiktok_account.choose_bc') }}
                        </el-button>

                        <el-form
                            ref="form_create_bc"
                            :model="form_create_bc.values"
                            :rules="form_create_bc.rules"
                        >
                            <el-form-item
                                :label="$t('common.name')"
                                prop="name"
                            >
                                <el-input
                                    v-model="form_create_bc.values.name"
                                    :placeholder="
                                        $t('input.placeholder.please_input')
                                    "
                                    size="medium"
                                    class="w-full"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                class="mt-6"
                                :label="$t('common.timezone')"
                                prop="timezone"
                            >
                                <el-select
                                    v-model="form_create_bc.values.timezone"
                                    filterable
                                    class="w-full"
                                    size="medium"
                                    :placeholder="
                                        $t('input.placeholder.please_select')
                                    "
                                >
                                    <el-option
                                        v-for="item in p__tiktok_timezones"
                                        :key="item.code"
                                        :label="`${item.time} - ${item.code}`"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <div class="text-center">
                                <el-button
                                    class="block mx-auto mt-8"
                                    icon="el-icon-plus"
                                    type="primary"
                                    size="medium"
                                    @click="submitFormCreateBC()"
                                    >{{ $t('button.create') }}
                                </el-button>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>

            <div
                v-if="active_collapse === 2 && show_buy_more"
                class="component-box mt-4"
            >
                <img
                    :src="require('@/assets/images/tiktok-money.webp')"
                    class="mx-auto h-[170px]"
                    alt=""
                />
                <p class="text-center mt-4">
                    Quý khách cần mua gói để sử dụng tính năng này
                </p>

                <div class="flex mt-2">
                    <el-button
                        type="success"
                        plain
                        size="small"
                        class="mx-auto"
                        @click="$router.push('/login')"
                        >Mua gói</el-button
                    >
                </div>
            </div>
        </div>

        <el-dialog
            :visible.sync="show_dialog.assign_to_bc_success"
            width="650px"
            class="el-dialog-center"
            :append-to-body="true"
            :close-on-click-modal="true"
        >
            <div>
                <img
                    :src="require('@/assets/images/winning_concept.jpg')"
                    class="w-[320px] h-[180px] mx-auto"
                    alt=""
                />
                <div
                    class="mt-4 text-xl font-medium text-center"
                    style="word-break: break-word"
                >
                    {{ $t('page.onboarding.complete_onboarding_message') }}
                    <br />
                    <!--                    {{ register_form.form.email }}-->
                </div>
                <div class="mt-8 text-center">
                    <el-button
                        type="success"
                        plain
                        icon="el-icon-money"
                        size="small"
                        @click="
                            $router.push(
                                `${p__group_route.ads_manager}/${p__route.payment}?advertiser_id=${form_assign_bc.advertiser_id}`
                            )
                        "
                    >
                        {{ $t('page.onboarding.add_balance_now') }}
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import {
    getAuthenticationLink,
    authenTiktokAccount
} from '@/services/atosa-tiktok-ads/tiktok-account'
import { getBusinessCenters } from '@/services/atosa-tiktok-ads/business-center'
import {
    createBCOnBoarding,
    createAdAccountOnBoarding,
    assignAdAccountToTargetBC
} from '@/services/atosa-tiktok-ads/on-boarding'
import { getTiktokAccounts } from '@/services/atosa-tiktok-ads/tiktok-account'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'

export default {
    mixins: [selectedTiktokBusinessMixin],

    data() {
        return {
            show_buy_more: false,
            new_window: null,
            has_connecting: false,
            max_bc_number: 2,
            selected_tiktok_business: null,
            business_centers: [],
            active_collapse: 1,
            is_create_fast: false,
            is_creating_bc: false,
            form_create_bc: {
                values: {
                    name: '',
                    timezone: 'Asia/Ho_Chi_Minh'
                },
                rules: {
                    name: [
                        {
                            required: true,
                            message: this.$t('input.rule.not_empty'),
                            trigger: 'blur'
                        }
                    ],
                    timezone: [
                        {
                            required: true,
                            message: this.$t('input.rule.not_empty'),
                            trigger: 'change'
                        }
                    ]
                }
            },
            form_create_ads_account: {
                values: {
                    target_bc_id: '',
                    advertiser_info: {
                        currency: this.p__default_currency_app,
                        name: '',
                        timezone: 'Asia/Ho_Chi_Minh'
                    },
                    billing_info: {
                        address: '',
                        vat: 'VAT'
                    },
                    customer_info: {
                        company: 'ATOSA',
                        industry: '292703',
                        registered_area: 'VN'
                    },
                    qualification_info: {
                        promotion_link: 'https://atosa.asia/'
                    }
                },
                rules: {
                    target_bc_id: [
                        {
                            required: true,
                            message: this.$t('input.rule.not_empty'),
                            trigger: 'blur'
                        }
                    ],
                    advertiser_info: {
                        currency: [
                            {
                                required: true,
                                message: this.$t('input.rule.not_empty'),
                                trigger: 'blur'
                            }
                        ],
                        name: [
                            {
                                required: true,
                                message: this.$t('input.rule.not_empty'),
                                trigger: 'blur'
                            }
                        ],
                        timezone: [
                            {
                                required: true,
                                message: this.$t('input.rule.not_empty'),
                                trigger: 'blur'
                            }
                        ]
                    },
                    qualification_info: {
                        promotion_link: [
                            {
                                required: true,
                                message: this.$t('input.rule.not_empty'),
                                trigger: 'blur'
                            }
                        ]
                    },
                    customer_info: {
                        company: [
                            {
                                required: true,
                                message: this.$t('input.rule.not_empty'),
                                trigger: 'blur'
                            }
                        ]
                    }
                }
            },
            form_assign_bc: {
                advertiser_id: null,
                target_bc_id: null
            },
            loading: {
                create_ad_account: false,
                assign_to_bc: false
            },
            show_dialog: {
                assign_to_bc_success: false
            }
        }
    },

    computed: {
        tiktok_businesses() {
            return this.$store.getters.tiktokBusiness.s__tiktok_businesses.filter(
                (item) => !item.is_system_account
            )
        },

        s__website() {
            return this.$store.getters.app.s__website
        },

        s__end_domain() {
            return this.$store.getters.app.s__end_domain
        },

        s__language() {
            return this.$store.getters.app.s__language
        }
    },

    watch: {
        is_create_fast() {
            this.form_create_ads_account.rules.target_bc_id[0] = {
                required: !this.is_create_fast,
                message: this.$t('input.rule.not_empty'),
                trigger: 'blur'
            }
        },

        s__website() {
            this.form_create_ads_account.values.qualification_info.promotion_link =
                this.s__website
        },

        is_creating_bc() {
            this.$refs['form_create_bc']?.resetFields()
        },

        active_collapse() {
            this.$refs['form_create_bc']?.resetFields()

            const temp_target_bc_id =
                this.form_create_ads_account.values.target_bc_id
            this.$refs['form_create_ads_account']?.resetFields()
            this.form_create_ads_account.values.target_bc_id = temp_target_bc_id
        },

        tiktok_businesses() {
            this.handleGetQuery()
        }
    },

    async mounted() {
        this.handleGetQuery()

        this.$router.replace({})

        // this.$router.push({
        //     path: this.$route.fullPath,
        //     query: {}
        // })
    },

    // beforeDestroy() {
    //     window.removeEventListener('message', this.listenMessage)
    // },

    methods: {
        async handleGetQuery() {
            const { bc_id, tiktok_business_id } = this.$route.query
            if (tiktok_business_id) {
                const tiktok_account_found = this.tiktok_businesses.find(
                    (i) => i.core_user_id === tiktok_business_id
                )
                if (tiktok_account_found) {
                    this.active_collapse = 2
                    await this.handleSelectTiktokAccount(tiktok_account_found)

                    if (bc_id) {
                        this.active_collapse = 3
                        await this.handleSelectedBC(bc_id)
                    }

                    // const query = Object.assign({}, this.$route.query)
                    // delete query.tiktok_business_id
                    // delete query.bc_id
                    // this.$router.replace({ query })
                }
            }
        },

        // listenMessage(event) {
        //     console.log(event)
        //     // Kiểm tra xem tin nhắn được gửi từ cửa sổ con
        //     if (event.source === this.newWindow && event.data === 'close') {
        //         // Xử lý tin nhắn được nhận từ cửa sổ con ở đây
        //         setTimeout(() => {
        //             this.newWindow.close()

        //             window.removeEventListener('message', this.listenMessage)
        //         }, 4567)
        //     }
        // },

        async handleConnectTiktokAccount() {
            // if (this.has_connecting) {
            //     return
            // }

            try {
                // this.has_connecting = true

                const response = await getAuthenticationLink()

                const url = response.data.link.replace(
                    'state=your_custom_params',
                    `state=${this.$store.state.user.s__user_info.token}-----${this.s__end_domain}-----${this.s__language}`
                )

                window.location = url
            } catch (error) {
                console.error(error)
            }
        },

        async fetchListTiktokAccounts() {
            const response = await getTiktokAccounts()

            if (response.code === 0 && response.data.list.length > 0) {
                await this.$store.dispatch(
                    'tiktokBusiness/s__setTiktokBusinesses',
                    response.data.list
                )
            } else {
                await this.$store.dispatch(
                    'tiktokBusiness/s__setTiktokBusinesses',
                    []
                )
            }
        },

        async authenTtAccount(auth_code) {
            try {
                const response = await authenTiktokAccount({
                    auth_code
                })

                if (response.code === 0) {
                    await this.fetchListTiktokAccounts()

                    const first_account = response.data

                    localStorage.setItem('id_tiktok_business', first_account.id)

                    await this.$store.dispatch(
                        'tiktokBusiness/s__setSelectedTiktokBusiness',
                        first_account
                    )

                    this.p__showNotify(
                        'success',
                        this.$t(
                            'page.connect_tiktok_account.connect_xxx_success',
                            {
                                xxx: first_account.user_name
                            }
                        )
                    )
                }
            } catch (error) {
                setTimeout(() => {
                    window.open(
                        `${this.p__group_route.ads_manager}/${this.p__route.tiktok_business}/connect`,
                        '_self'
                    )
                }, 1500)
                console.error(error)
            }
        },

        submitFormCreateBC() {
            this.$refs['form_create_bc'].validate((valid) => {
                if (valid) {
                    this.handleCreateBC().catch(console.error)
                }
            })
        },

        submitFormCreateAdsAccount() {
            this.$refs['form_create_ads_account'].validate(async (valid) => {
                if (valid) {
                    try {
                        await this.handleCreateAdsAccount()
                        // if (result) this.active_collapse = 3
                    } catch (e) {
                        console.error(e)
                    }
                }
            })
        },

        async handleCreateAdsAccount() {
            this.m__loading = true

            try {
                const created_response = await createAdAccountOnBoarding(
                    this.selected_tiktok_business
                        ? this.selected_tiktok_business.id
                        : '63b411e8f7cac99fc0591421',
                    this.form_create_ads_account.values
                )

                this.p__showNotify(
                    'success',
                    this.$t(
                        'page.connect_tiktok_account.message_connect_ads_account_success'
                    )
                )

                setTimeout(() => {
                    window.location = `${this.p__group_route.ads_manager}/${this.p__route.create_campaign}?advertiser_id=${created_response.data.advertiser_id}`
                }, 1111)
            } catch (e) {
                if (e.data.message === 'Đã đạt tối đa số tài khoản quảng cáo') {
                    this.show_buy_more = true
                }
            }

            this.m__loading = false
        },

        async handleCreateBC() {
            if (
                !this.selected_tiktok_business ||
                !this.selected_tiktok_business.id
            ) {
                return
            }

            this.m__loading = true

            try {
                const response = await createBCOnBoarding(
                    this.selected_tiktok_business.id,
                    {
                        bc_name: this.form_create_bc.values.name,
                        timezone: this.form_create_bc.values.timezone
                    }
                )

                if (response.code === 0) {
                    await this.getBCByAccount()
                    this.is_creating_bc = false
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async getBCByAccount() {
            if (
                !this.selected_tiktok_business ||
                !this.selected_tiktok_business.id
            ) {
                return
            }

            this.m__loading = true

            try {
                const response = await getBusinessCenters(
                    this.selected_tiktok_business.id
                )

                if (response.code === 0) {
                    this.business_centers = response.data
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        handleSelectedBC(bc_id) {
            this.form_assign_bc.target_bc_id = bc_id
        },

        async handleSelectTiktokAccount(account) {
            this.is_create_fast = false

            this.selected_tiktok_business = account

            await this.getBCByAccount()

            this.is_creating_bc = this.business_centers.length === 0

            this.active_collapse = 2
        },

        async assignAdAccountToTargetBC() {
            if (
                !this.form_assign_bc.advertiser_id ||
                !this.form_assign_bc.target_bc_id
            ) {
                return
            }

            this.m__loading = true
            try {
                await assignAdAccountToTargetBC(
                    this.selected_tiktok_business.id,
                    this.form_assign_bc
                )
                this.show_dialog.assign_to_bc_success = true
            } catch (e) {
                console.error(e)
            } finally {
                this.m__loading = false
            }
        }
    }
}
</script>
