import request from './_config'

const path = `onboarding`

export function createBCOnBoarding(tiktok_account_id, data) {
    return request({
        url: `${path}/bc/${tiktok_account_id}`,
        method: 'post',
        data: {
            bc_name: data.bc_name,
            timezone: data.timezone
        }
    })
}

export function getAdAccountOnBoarding(tiktok_account_id) {
    return request({
        url: `${path}/ad_account/${tiktok_account_id}`,
        method: 'get'
    })
}

export function getAdAccountOnBoardingDetail(
    tiktok_account_id,
    ad_account_ids
) {
    return request({
        url: `${path}/ad_account/detail/${tiktok_account_id}`,
        method: 'get',
        params: { ad_account_ids: JSON.stringify(ad_account_ids) }
    })
}

export function createAdAccountOnBoarding(tiktok_account_id, data) {
    return request({
        url: `${path}/ad_account/${tiktok_account_id}`,
        method: 'post',
        data: {
            target_bc_id: data.target_bc_id,
            advertiser_info: {
                currency: data.advertiser_info.currency,
                name: data.advertiser_info.name,
                timezone: data.advertiser_info.timezone
            },
            billing_info: {
                address: data.billing_info.address,
                vat: data.billing_info.vat
            },
            customer_info: {
                company: data.customer_info.company,
                industry: data.customer_info.industry,
                registered_area: data.customer_info.registered_area
            },
            qualification_info: {
                promotion_link: data.qualification_info.promotion_link
            }
        }
    })
}

export function assignAdAccountToTargetBC(tiktok_account_id, data) {
    return request({
        url: `${path}/ad_account/assign_bc/${tiktok_account_id}`,
        method: 'post',
        data
    })
}
